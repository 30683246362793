<div class="like">
  <div class="like__item" [class]="{ 'like__item-active': model.isLike }" (click)="onFinger(true)">
    <ln-icon
      name="like"
      width="20px"
      height="21px"
      class="like__item-icon--hidden"
      [class]="{
        'like__item-icon--like': (anim$ | async) === true
      }"
    ></ln-icon>
    <ln-icon name="like" width="20px" height="21px"></ln-icon><span>{{ model.likes }}</span>
  </div>
  <div
    class="like__item like__item-dislike"
    [class]="{ 'like__item-active': model.isDislike }"
    (click)="onFinger(false)"
  >
    <ln-icon
      name="like"
      width="20px"
      height="21px"
      class="like__item-icon--hidden"
      [class]="{
        'like__item-icon--dislike': (anim$ | async) === false
      }"
    ></ln-icon>
    <ln-icon name="like" width="20px" height="21px"></ln-icon><span>{{ model.dislikes }}</span>
  </div>
</div>
