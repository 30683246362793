<label class="ln-checkbox" [class]="{ disabled }">
  <input
    #inputRef
    type="checkbox"
    [checked]="value"
    [(ngModel)]="value"
    (focus)="onFocus($event, true)"
    (blur)="onFocus($event, false)"
    [disabled]="disabled"
    [attr.name]="name || String(formControlName) || ''"
  />
  <span class="ln-checkbox__icon">
    <ln-icon name="checkmark" width="10px" height="7px"></ln-icon>
  </span>
  <span *ngIf="label">{{ label }}</span>
  <ng-content></ng-content>
  <span *ngIf="error" class="ln-checkbox__subtext validate-error-text">
    {{ error }}
  </span>
</label>
