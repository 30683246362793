import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AbstractValueAccessor, MakeValueProvider } from '@app/shared/utils';
import { SharedModule } from '@shared/shared.module';

@Component({
    selector: 'ln-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MakeValueProvider(InputComponent)],
    imports: [FormsModule, SharedModule]
})
export class InputComponent extends AbstractValueAccessor implements OnInit {
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() subText = '';
  @Input() subTextColor: string | undefined;
  @Input() subTextRightAlign = false;
  @Input() rows?: number;
  @Input() maxLength?: number;
  @Input() type: string = 'text';
  @Input() autocomplete?: string;
  @Input() name?: string;
  @Input() id?: string;
  @Input() formControlName?: string;
  @Input() showCount?: boolean;
  @Input() ngModelOptions!: {
    name?: string;
    standalone?: boolean;
    updateOn?: any;
  };
  @HostBinding('class.autoresize') @Input() autoResize = false;
  @HostBinding('class.bordered') @Input() bordered = false;
  @HostBinding('class.small') @Input() small = false;
  @HostBinding('class.big') @Input() big = false;
  @HostBinding('class.light') @Input() light = false;
  @HostBinding('class.textarea') isTextArea = false;
  @HostBinding('class.focused') isFocused = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter();
  @Output() clear = new EventEmitter();

  @ViewChild('inputRef') inputElement!: ElementRef;

  onClear(event: Event) {
    event.stopPropagation();
    this.value = '';
    this.clear.emit();
    this.inputElement.nativeElement.focus();
  }

  onFocus(e: Event, value: boolean) {
    this.isFocused = value;
    (value ? this.focus : this.blur).emit(e);
    if (!value) this.onTouchedCallback();
  }

  ngOnInit() {
    this.isTextArea = this.rows !== undefined;
    if (this.eRef) {
      this.renderer.removeAttribute(this.eRef.nativeElement, 'id');
      this.renderer.removeAttribute(this.eRef.nativeElement, 'autocomplete');
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (!this.inputElement) return;
    this.renderer.setProperty(this.inputElement.nativeElement, 'disabled', isDisabled);
  }

  getHeight(text: string) {
    if (!text || !this.autoResize) return;
    const v1 = Math.floor(text.length / 50);
    const v2 = text.split('\n').length;
    return Math.max(v1, v2);
  }
}
