import { NgModule } from '@angular/core';
import { LikeComponent } from './like.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [LikeComponent],
  exports: [LikeComponent]
})
export class LikeModule {}
