export interface Option {
  value: any;
  name: any;
  disabled?: boolean;
}

export interface ValuePlaceholder {
  value: number;
  placeheloder: string;
}

export interface Select {
  value?: any;
  options?: Option[];
  visible?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export enum OrderSymb {
  d = 'd',
  m = 'm',
  y = 'y'
}

export interface Selects {
  [OrderSymb.d]: Select;
  [OrderSymb.m]: Select;
  [OrderSymb.y]: Select;
}
