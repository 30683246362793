<label class="ln-radio" [class]="{ disabled }">
  <input
    type="radio"
    (focus)="onFocus($event, true)"
    (blur)="onFocus($event, false)"
    [disabled]="disabled"
    [attr.name]="name"
    [attr.value]="returnValue"
    [checked]="checked"
    (change)="onChange($event)"
  />
  <div class="ln-radio__label">{{ label }}</div>
</label>
