import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
@Component({
    selector: 'ln-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TagComponent {
  @Input() label!: string;

  @HostBinding('class.disabled') @Input() disabled = false;
  @HostBinding('class.active') @Input() active = false;
  @HostBinding('class.bordered') @Input() bordered = false;
}
