import { NgModule } from '@angular/core';
import { ChipComponent } from './chip.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [ChipComponent],
  exports: [ChipComponent]
})
export class ChipModule {}
