<div *ngIf="label" class="ln-tag-group__header">
  <span class="ln-tag-group__header-label label-text">{{ label }}</span>
  <span class="ln-tag-group__header-less button-text" *ngIf="isShowedMore" (click)="onMore($event)">
    {{ 'controls.tag-group.viewLess' | translate }}</span>
</div>
<div
  class="ln-tag-group"
  [class]="{
    'showed-all': isShowedMore
  }"
>
  <ln-tag *ngFor="let item of lessItems; let idx = index"
    [label]="item.name"
    (click)="onTag(idx)"
    (touchstart)="$event.stopPropagation()"
    [active]="selected[idx]"
  >
  </ln-tag>
  <ln-tag
    *ngIf="viewMore && !isShowedMore && items.length > lessItems.length"
    [bordered]="true"
    [active]="true"
    label="{{ 'controls.tag-group.viewMore' | translate }}"
    (click)="onMore($event)"
  ></ln-tag>
  <div class="ln-tag-group__last-tag"></div>
</div>
