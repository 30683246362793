import { NgModule } from '@angular/core';
import { SelectDateComponent } from './select-date.component';
import { CommonModule } from '@angular/common';
import { SelectModule } from '../select/select.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, SelectModule, ReactiveFormsModule],
  declarations: [SelectDateComponent],
  exports: [SelectDateComponent]
})
export class SelectDateModule {}
