import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractValueAccessor, MakeValueProvider } from '@app/shared/utils';
import { ItemToggle } from './toggle.types';

@Component({
    selector: 'ln-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MakeValueProvider(ToggleComponent)],
    standalone: false
})
export class ToggleComponent extends AbstractValueAccessor {
  @Input() label!: string;
  @Input() disabled = false;
  @Input() name!: string;
  @Input() items: ItemToggle[] = [];

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter();

  onFocus(e: Event, value: boolean) {
    (value ? this.focus : this.blur).emit(this.value);
  }

  onChange(item: ItemToggle) {
    this.value = item.value;
    // this.onChangeCallback(this.returnValue);
  }
}
