import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectComponent } from './select.component';

@NgModule({
  imports: [SharedModule, NgSelectModule, FormsModule],
  declarations: [SelectComponent],
  exports: [SelectComponent]
})
export class SelectModule {}
