import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReactionPut, ReactionRequest, ReactionResult } from '@app/shared/models/api';
import { HttpService, LocalStorageService } from '@app/shared/services';
import { ApiMethods, Sections } from '@app/shared/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const storageKey = 'lad.reactions';
export interface ReactionControl {
  isLike?: boolean | null;
  isDislike?: boolean | null;
  likes?: number;
  dislikes?: number;
}
@Component({
  selector: 'ln-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LikeComponent implements OnInit {
  @Input()
  get reactions(): typeof this.model | null {
    return this.model;
  }
  set reactions(model) {
    this.model = model || {};
    this.cdr.detectChanges();
  }

  @Input() section?: Sections;
  @Input() idRecord?: number;
  @Input() idAnswer?: number;
  @Input() isEntityEngine = true;
  @Input() httpFun?: (reaction: number, httpService: HttpService) => Observable<unknown>;
  @Output() reactionsChange = new EventEmitter<ReactionControl>();

  initAt?: number;
  likeClick$ = new Subject();

  anim$ = new BehaviorSubject<null | boolean>(null);
  model: ReactionControl = {};

  constructor(
    private route: ActivatedRoute,
    private http: HttpService,
    private storage: LocalStorageService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initAt = Date.now();
    if (this.isEntityEngine) {
      const storage = this.getStorageData() as any;
      if (storage) {
        this.model.isLike = storage.previousValue === true;
        this.model.isDislike = storage.previousValue === false;
      }
    }
  }

  get id() {
    return this.idRecord || this.route.snapshot.params['id'];
  }

  onFinger(isLike: boolean) {
    if (!this.isEntityEngine) {
      const isCancelReaction = (isLike && this.model.isLike) || (!isLike && this.model.isDislike);

      if (isLike) {
        this.model.isLike = !this.model.isLike;
        this.model.isDislike = false;
      } else {
        this.model.isDislike = !this.model.isDislike;
        this.model.isLike = false;
      }

      const reaction = isCancelReaction ? 0 : isLike ? 1 : -1;
      if (this.httpFun) {
        this.httpFun(reaction, this.http).subscribe(() => this.reactionsChange.emit(this.model));
      } else this.reactionsChange.emit(this.model);
      this.cdr.detectChanges();
    } else {
      this.anim$.next((isLike && this.model.isLike) || (!isLike && this.model.isDislike) ? null : isLike);
      const storage = this.getStorageData();
      this.http
        .put<ReactionRequest, ReactionResult>(
          { method: ApiMethods.Reaction, params: [this.section, this.id] },
          {
            time: Date.now() - (this.initAt || 0),
            value: isLike ? (this.model.isLike ? null : true) : this.model.isDislike ? null : false,
            ...(!!this.idAnswer && { id: this.idAnswer }),
            ...storage
          }
        )
        .subscribe((result) => {
          if (this.reactions) {
            this.reactions.likes = result.data.likes || 0;
            this.reactions.dislikes = result.data.dislikes || 0;
          }

          if (isLike) {
            this.model.isLike = !this.model.isLike;
            this.model.isDislike = false;
          } else {
            this.model.isDislike = !this.model.isDislike;
            this.model.isLike = false;
          }

          this.reactionsChange.emit(this.model);

          this.saveStorageData(result.data);
          this.cdr.detectChanges();
        });
    }
  }

  getStorageId() {
    return `${this.id}${this.idAnswer ? '-' + this.idAnswer : ''}`;
  }

  getStorageData() {
    try {
      const data = JSON.parse(this.storage.getItem(`${storageKey}.${this.section}`) || '');
      return !!data ? data[this.getStorageId()] : {};
    } catch {}
    return {};
  }

  saveStorageData(data: ReactionPut) {
    const key = `${storageKey}.${this.section}`;
    let local = { [this.getStorageId()]: {} };
    const storageData = this.storage.getItem(key) || '';

    try {
      local = JSON.parse(storageData);
    } catch {}

    if (!data['date'] || !data['ip']) this.storage.removeItem(key);
    else {
      local[this.getStorageId()] = {
        ip: data.ip,
        date: data.date,
        previousValue: data.value
      };

      this.storage.setItem(key, JSON.stringify(local));
    }
  }
}
