import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ln-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChipComponent {
  @Input() name?: string;
  @Output() delete: EventEmitter<null>;

  constructor() {
    this.delete = new EventEmitter<null>();
  }

  public onDelete() {
    this.delete.emit();
  }
}
