import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from './toggle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [FormsModule, CommonModule, ReactiveFormsModule],
  declarations: [ToggleComponent],
  exports: [ToggleComponent]
})
export class ToggleModule {}
