import {
  Component,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  ViewChild,
  Renderer2,
  ChangeDetectionStrategy
} from '@angular/core';
import { AbstractValueAccessor, isOwnElement } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import {
  UploadOutput,
  UploadInput,
  humanizeBytes,
  UploadStatus,
  UploadFile,
  UploaderOptions
} from '@angular-ex/uploader';
import { OutputFileEvent, UploaderOptionsExt, UploadFileExt } from './file-upload.types';

const DEFAULT_FILE_ICON = 'article';
const IMAGE_FILE_ICON = 'image';
const ARCHIVE_FILE_ICON = 'archive';

@Component({
  selector: 'ln-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FileUploadComponent extends AbstractValueAccessor {
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() canDownload = false;
  @Input() options?: UploaderOptionsExt;
  @Input() autoupload = false;
  @Input() url = '';
  @Input() subText = '';
  @Input() hideIdentity = true;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter();
  @Output() addFiles = new EventEmitter<OutputFileEvent>();
  @Output() removeFiles = new EventEmitter<OutputFileEvent>();

  @ViewChild('inputRef') inputRef!: ElementRef;

  isFocused = false;
  override innerValue: UploadFileExt[] = [];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver?: boolean;
  error = '';

  private eventDefault: UploadInput = {
    type: 'uploadAll',
    url: this.url,
    method: 'POST'
  };

  constructor(
    private translate: TranslateService,
    protected elRef: ElementRef,
    protected renderer2: Renderer2
  ) {
    super();
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  get limitsText() {
    const { maxUploads = 0, maxAllSize = 0 } = this.options || {};
    if (maxUploads && maxAllSize) return 'components.fileUpload.limit';
    else if (maxUploads) return 'components.fileUpload.limitFiles';
    else if (maxAllSize) return 'components.fileUpload.limitSize';
    else return '';
  }

  get files() {
    return (this.innerValue || []).filter((file) => !file.deleted);
  }

  get optionsInner(): UploaderOptions {
    const { maxUploads = 0, maxAllSize, concurrency = Number.POSITIVE_INFINITY, ...rest } = this.options || {};
    return { ...rest, concurrency, maxUploads: maxUploads - this.loadedCount };
  }

  get loadedCount() {
    return (this.innerValue || []).length;
  }

  validAllSize(newFile: UploadFile) {
    if (this.options?.maxAllSize === undefined) return true;
    else
      return (this.innerValue || []).reduce((prev, file) => prev + file.size, newFile.size) <= this.options.maxAllSize;
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      if (this.autoupload) this.uploadInput.emit(this.eventDefault);
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      this.error = '';

      if (this.inputRef && (!this.innerValue.length || this.disabled)) this.inputRef.nativeElement.value = '';
      if (!this.disabled) {
        if (!this.validAllSize(output.file)) {
          this.error = this.translate.instant('components.fileUpload.errorLimitAllSize', {
            fileName: output.file.name
          });
        } else {
          this.value.push(output.file);
          this.addFiles.emit({ files: this.value, file: output.file });
        }
      }
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      const index = this.innerValue.findIndex(
        (file) => typeof output.file !== 'undefined' && file.id === output.file.id
      );
      this.value[index] = output.file;
    } else if (output.type === 'cancelled' || output.type === 'removed') {
      this.value = this.innerValue.filter((file) => file !== output.file);
      this.removeFiles.emit({ files: this.value, file: output.file });
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
      this.error = this.translate.instant('components.fileUpload.error', { fileName: output.file.name });
      console.log(output.file.name + ' rejected');
    }

    this.value = this.innerValue.filter((file) => file.progress?.status !== UploadStatus.Done);
    this.cdr.detectChanges();
  }

  startUpload(): void {
    this.uploadInput.emit(this.eventDefault);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(file: UploadFileExt): void {
    file.deleted = true;
    this.uploadInput.emit({ type: 'remove', id: file.id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  onBlur(e: FocusEvent) {
    if (this.isFocused && e.relatedTarget === null) {
      this.isFocused = false;
      return;
    } else if (e.target instanceof HTMLElement && !isOwnElement(e.target, this.eRef.nativeElement)) {
      this.blur.emit(e);
      this.isFocused = false;
    }
  }

  getFileIconByMimeType(type: string) {
    if (!type) {
      return DEFAULT_FILE_ICON;
    } else
      return type.indexOf('image') >= 0
        ? IMAGE_FILE_ICON
        : type.indexOf('zip') >= 0
          ? ARCHIVE_FILE_ICON
          : DEFAULT_FILE_ICON;
  }

  onChangeIdentity(file: UploadFileExt) {
    this.value = this.files;
  }
}
