@use 'typography' as *;
@use 'vars' as *;
@use 'direction' as *;

$ng-select-primary-text: var(--color-select-text, var(--ln-color-primary-light)) !default;
$ng-select-disabled-text: var(--ln-color-text-gray-dark) !default;
$ng-select-disabled-bg: var(--ln-color-background-gray4) !default;
$ng-select-border: var(--ln-color-border-gray2) !default;
$ng-select-marked: var(--ln-color-border-gray) !default;
$ng-select-padding-start: var(--select-padding-start, 11px) !default;

ln-select {
  display: block;
  position: relative;

  --color-input-background: var(--color-select-background, var(--ln-color-background-gray));
  --color-select-border: transparent;
  --color-highlighted: transparent;

  &:not(.ng-select-disabled) {
    &.ng-invalid.ng-touched {
      --color-highlighted: var(--ln-color-error);
      --color-select-border: var(--ln-color-error);

      .ln-select__subtext.error {
        color: var(--ln-color-error);
      }
    }

    &:not(.ng-invalid.ng-touched) .ln-select__subtext.error {
      display: none;
    }
  }

  &.light {
    --color-select-border: var(--ln-color-border-gray2);
    --color-input-background: var(--ln-color-background);
    --color-highlighted: var(--ln-color-text-gray);
  }

  .ln-select {
    &__label {
      display: block;
      color: var(--ln-color-primary-light);
      margin-bottom: 12px;
    }

    &__input {
      display: flex;
      align-items: center;
      background: var(--color-input-background);
      border: 1px solid var(--color-select-border);
      border-radius: 4px;

      &-prefix:empty {
        @include rtl-prop(padding-left, padding-right, 0, false);
      }

      &-prefix {
        @include rtl-prop(padding-left, padding-right, 16px, false);

        line-height: 1;
      }
    }

    &__subtext {
      @include sub-text;

      position: absolute;
      top: calc(100% + 3px);

      &.warning {
        color: var(--ln-color-secondary);
      }
    }
  }
}

.ng-select {
  .ng-clear-wrapper {
    color: var(--ln-color-background);
    width: 14px !important;
    height: 14px !important;
    right: 7px;
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.333374 7.00004C0.333374 10.682 3.31804 13.6667 7.00004 13.6667C10.682 13.6667 13.6667 10.682 13.6667 7.00004C13.6667 3.31804 10.682 0.333374 7.00004 0.333374C3.31804 0.333374 0.333374 3.31804 0.333374 7.00004ZM5.11471 4.17137L7.00004 6.05737L8.88537 4.17137L9.82871 5.11471L7.94271 7.00004L9.82871 8.88537L8.88537 9.82871L7.00004 7.94271L5.11471 9.82871L4.17137 8.88537L6.05737 7.00004L4.17137 5.11471L5.11471 4.17137Z" fill="%23BFC7D1"/></svg>');

    @include rtl(false) {
      left: 7px;
      right: auto;
    }

    .ng-clear {
      display: none !important;
    }

    &:focus-visible {
      outline: var(--focus-frame, 1px solid var(--ln-color-text-gray));
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      border-color: var(--ln-color-text-gray);

      .ng-arrow-wrapper {
        transform: rotate(180deg);
        opacity: 1;

        @include rtl(false) {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      border-color: var(--color-highlighted);
      box-shadow: 0px 0px 0px 1px var(--color-highlighted);
    }
  }

  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $ng-select-disabled-bg;

      .ng-value-container {
        .ng-value {
          color: $ng-select-disabled-text;
        }
      }

      .ng-arrow-wrapper {
        display: none;
      }
    }
  }

  &:not(.ng-select-disabled) {
    > .ng-select-container {
      cursor: pointer;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    color: $ng-select-primary-text;
    background-color: var(--color-select-background);
    border-radius: 4px;
    min-height: 36px;
    align-items: center;
    transition: all 0.3s;
    box-sizing: border-box;

    @include rtl-prop(padding-right, padding-left, 11px, false);
    @include rtl-prop(padding-left, padding-right, $ng-select-padding-start, false);

    .ng-arrow-wrapper {
      width: 16px;
      height: 26px;
      transition: transform 0.3s;

      .ng-arrow {
        border-style: solid;
        border-width: 0.1rem 0.1rem 0 0;
        height: 8px;
        width: 8px;
        transform: rotate(135deg);
        color: $ng-select-disabled-text;
      }
    }

    .ng-input {
      line-height: 32px;

      input {
        color: $ng-select-primary-text;
        font: inherit;
      }
    }

    .ng-value-container {
      align-items: center;

      .ng-value {
        @include margin-end(4px, false);

        font-weight: 600;
        color: $ng-select-primary-text;
      }

      .ng-placeholder {
        font-weight: 400;
        color: var(--ln-color-text-gray);
      }
    }
  }

  &.ng-select-single {
    &.ng-select-opened .ng-select-container .ng-value {
      opacity: 0.4;
    }

    .ng-select-container {
      .ng-value-container {
        .ng-input {
          top: 2px;
          left: 0;
          padding-left: 12px;
          padding-right: 50px;

          @include rtl(false) {
            padding-right: 12px;
            padding-left: 50px;
          }
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-bg;

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {
      min-height: 36px;
      padding-left: 5px;

      @include rtl(false) {
        padding-right: 5px;
        padding-left: 11px;

        .ng-value-container .ng-placeholder {
          margin-left: 0;
          margin-right: 6px;
        }
      }

      .ng-value-container {
        padding-bottom: 3px;

        .ng-value {
          margin-top: 3px;
          margin-right: 4px;
          font-size: 0.9em;
          background-color: var(--ln-color-background-gray4);
          border: 1px solid var(--ln-color-border-gray2);
          border-radius: 2px;
          height: 24px;
          line-height: 22px;

          @include rtl(false) {
            margin-right: 0;
            margin-left: 4px;
          }

          &.ng-value-disabled {
            background-color: $ng-select-disabled-bg;
            color: $ng-select-disabled-text;

            .ng-value-label {
              padding-left: 5px;

              @include rtl(false) {
                padding-left: 0;
                padding-right: 5px;
              }
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 0 5px;
          }

          .ng-value-icon {
            display: inline-block;
            padding: 0 5px;
          }
        }

        .ng-placeholder {
          top: 50%;
          height: 20px;
          margin-top: -10px;
          margin-left: 6px;
        }

        .ng-input {
          height: 24px;
          margin-top: 3px;
        }
      }
    }
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;

    @include rtl(false) {
      padding: 5px 0 0 5px;
    }
  }
}

.ng-dropdown-panel {
  @include rtl-value(text-align, left, right);

  --start-position: auto;

  background-color: var(--ln-color-background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;

  width: auto !important;
  left: var(--start-position) !important;

  visibility: hidden;

  &.ng-select-top {
    bottom: 100%;
    border-bottom-color: var(--ln-color-border-gray2);
  }

  &.ng-select-right {
    left: 100%;
    top: 0;
    border-bottom-color: var(--ln-color-border-gray2);
    margin-top: 0;
    margin-left: 4px;
  }

  &.ng-select-bottom {
    top: 100%;
    border-top-color: var(--ln-color-border-gray2);
  }

  &.ng-select-left {
    left: calc(-100% - 4px);
    top: 0;
    border-bottom-color: var(--ln-color-border-gray2);
    margin-top: 0;
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      padding: 4px 12px;
      color: rgba(0, 0, 0, 0.45);
      cursor: pointer;

      &.ng-option-disabled {
        cursor: default;
        color: rgba(0, 0, 0, 0.45);
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
      }

      &.ng-option-selected {
        background-color: var(--ln-color-background-gray4);
        font-weight: 600;
      }

      &.ng-option-selected.ng-option-marked {
        background-color: $ng-select-marked;
      }
    }

    .ng-option {
      background-color: var(--ln-color-background);
      color: var(--ln-color-primary);
      padding: 5px 12px;
      transition: background 0.3s ease;
      white-space: normal !important;

      &:first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &.ng-option-selected {
        background-color: #fafafa;
        font-weight: 600;
      }

      &.ng-option-selected.ng-option-marked {
        color: $ng-select-primary-text;
        background-color: $ng-select-marked;
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $ng-select-primary-text;
      }

      &.ng-option-disabled {
        color: $ng-select-disabled-text;
        cursor: not-allowed;
      }

      &.ng-option-child {
        padding-left: 20px;

        @include rtl(false) {
          padding-right: 20px;
          padding-left: 0;
        }
      }

      .ng-option-label {
        font-size: 14px;
      }

      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;

        @include rtl(false) {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
  }
}

.invert-h-align {
  right: 0 !important;
  left: auto !important;

  @include rtl(false) {
    left: 0 !important;
    right: auto !important;
  }
}

.visible-dropdown {
  visibility: visible !important;
}
