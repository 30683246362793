import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { AbstractValueAccessor, MakeValueProvider } from '@app/shared/utils';

@Component({
  selector: 'ln-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MakeValueProvider(RadioComponent)],
  standalone: false
})
export class RadioComponent extends AbstractValueAccessor {
  @Input() label!: string;
  @Input() disabled = false;
  @Input() checked!: boolean;
  @Input() name!: string;
  @Input() returnValue!: any;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter();

  constructor(
    protected elRef: ElementRef,
    protected renderer2: Renderer2
  ) {
    super();
  }

  override writeValue(value: any) {
    this.checked = this.returnValue === value;
    super.writeValue(value);
  }

  onFocus(e: Event, value: boolean) {
    (value ? this.focus : this.blur).emit(this.value);
  }

  onChange(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) return;
    this.onChangeCallback(this.returnValue);
    this.valueChange.emit(this.returnValue);
  }
}
