@for (item of items; track item.value) {
  <label class="toggle__item" [class]="{ disabled }">
    <input
      type="radio"
      (focus)="onFocus($event, true)"
      (blur)="onFocus($event, false)"
      [disabled]="disabled"
      [attr.name]="name"
      [attr.value]="item.value"
      [checked]="item.value === value"
      (change)="onChange(item)"
    />
    <span class="toggle__item-label">{{ item.label }}</span>
  </label>
}
