<label *ngIf="label" [attr.for]="formControlName" class="ln-select-date__label label-text" required>{{ label }}</label>
<div class="ln-select-date" [formGroup]="form">
  <ng-container *ngFor="let type of orders; let idx = index">
    <ln-select
      *ngIf="selects[type].visible"
      [items]="selects[type].options || []"
      keyValue="value"
      keyLabel="name"
      [value]="selects[type].value"
      [formControlName]="type"
      (valueChange)="onChange(type, $event)"
      (blur)="onBlur($event)"
      [attr.disabled]="selects[type].disabled"
      [placeholder]="placeHolders[idx] || ''"
      [light]="true"
      [clearable]="clearable"
      [selectOnTab]="true"
      [required]="!!required"
      [id]="type === OrderSymb.d ? formControlName || '' : type === OrderSymb.m ? labelIdM : labelIdY"
    ></ln-select>
  </ng-container>
</div>
<div class="ln-select-date__subtext validate-error-text" *ngIf="subText">
  {{ subText }}
</div>
