// adaptation https://github.com/katafractari/ngx-combo-datepicker
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validator } from '@angular/forms';
import { AbstractValueAccessor, isOwnElement, MakeValidatorProvider, MakeValueProvider } from '@app/shared/utils';
import { getMaxDate, parseDate, parseIntStrict } from '@app/shared/utils/date-utils';
import { OrderSymb, Selects, Option } from './select-date.types';
import { nanoid } from 'nanoid';

@Component({
    selector: 'ln-select-date',
    templateUrl: './select-date.component.html',
    styleUrls: ['./select-date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MakeValidatorProvider(SelectDateComponent), MakeValueProvider(SelectDateComponent)],
    host: {
        '(blur)': 'onTouchedCallback()'
    },
    standalone: false
})
export class SelectDateComponent
  extends AbstractValueAccessor
  implements OnInit, OnChanges, Validator, AfterContentChecked
{
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() months?: string;
  @Input() order?: string | string[];
  @Input() yearOrder: string = 'desc';
  @Input() timezone?: number;
  @Input() placeholder?: string;
  @Input() disabled?: boolean | boolean[];
  @Input() visible: boolean[] = [true, true, true];
  @Input() label!: string;
  @Input() subText = '';
  @Input() clearable = true;
  @Input() required?: boolean;
  @Input() formControlName?: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter();

  OrderSymb = OrderSymb;
  labelIdM: string;
  labelIdY: string;

  public selects: Selects = {
    [OrderSymb.d]: { visible: true },
    [OrderSymb.m]: { visible: true },
    [OrderSymb.y]: { visible: true }
  };

  protected form = new FormGroup({
    [OrderSymb.d]: new FormControl(null),
    [OrderSymb.m]: new FormControl(null),
    [OrderSymb.y]: new FormControl(null)
  });

  private monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  private _minDate!: Date | null;
  private _maxDate!: Date | null;
  protected placeHolders: string[] = [];
  public orders: OrderSymb[] = [OrderSymb.d, OrderSymb.m, OrderSymb.y];

  constructor(
    protected elRef: ElementRef,
    protected renderer2: Renderer2
  ) {
    super();

    this.labelIdM = nanoid();
    this.labelIdY = this.labelIdM + 'Y';
  }

  ngOnInit() {
    this.innerValue = parseDate(this.value, this.timezone);
    if (typeof this.disabled === 'boolean') {
      this.selects.d.disabled = this.disabled;
      this.selects.m.disabled = this.disabled;
      this.selects.y.disabled = this.disabled;
    } else if (this.disabled instanceof Array && this.disabled.length === 3) {
      this.selects.d.disabled = this.disabled[0];
      this.selects.m.disabled = this.disabled[1];
      this.selects.y.disabled = this.disabled[2];
    }

    if (typeof this.visible === 'boolean') {
      this.selects.d.visible = this.visible;
      this.selects.m.visible = this.visible;
      this.selects.y.visible = this.visible;
    } else if (this.visible instanceof Array && this.visible.length === 3) {
      this.selects.d.visible = this.visible[0];
      this.selects.m.visible = this.visible[1];
      this.selects.y.visible = this.visible[2];
    }

    if (typeof this.order !== 'string') {
      this.orders = 'dmy'.split('') as OrderSymb[];
    } else {
      this.orders = this.order.toLowerCase().split('') as OrderSymb[];
    }

    this._minDate = parseDate(this.minDate, this.timezone);
    if (this.minDate == null) {
      const now = new Date();
      this._minDate = new Date(
        now.getFullYear() - 100,
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds(),
        now.getMilliseconds()
      );
    }
    this._maxDate = parseDate(this.maxDate, this.timezone);
    if (this._maxDate == null) {
      this._maxDate = new Date();
    }

    if (this.value && this._minDate !== null && this.value < this._minDate) {
      this.innerValue = this._minDate;
    }
    if (this.value && this.value > this._maxDate) {
      this.innerValue = this._maxDate;
    }

    if (this.months !== undefined && this.months !== null) {
      if (typeof this.months === 'string') {
        const months = this.months.split(',');
        if (months.length === 12) {
          this.monthNames = months;
        }
      }
      if (Array.isArray(this.months) && this.months.length === 12) {
        this.monthNames = this.months;
      }
    }

    this.updatePlaceholders();

    this.updateMonthList('');
    this.updateYearList('');
    this.updateDateList('', '');
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  onChange(type: OrderSymb, value: Option) {
    this.selects[type].value = value;
    let res = null;

    const d = this.selects.d.value;
    const m = this.selects.m.value;
    const y = this.selects.y.value;

    if (d && !isNaN(m) && y != null && y) {
      const maxDay = getMaxDate(m + 1, y);

      let hours = 0;
      let minutes = 0;
      let seconds = 0;
      let milliseconds = 0;
      if (this.value != null) {
        hours = this.value.getHours();
        minutes = this.value.getMinutes();
        seconds = this.value.getSeconds();
        milliseconds = this.value.getMilliseconds();
      }
      if (d > maxDay) this.selects.d.value = null;
      else res = new Date(y, m, d > maxDay ? maxDay : d, hours, minutes, seconds, milliseconds);
    }
    this.onChangeCallback(res);

    this.updateMonthList(y);
    this.updateYearList(m);
    this.updateDateList(m, y);
  }

  override writeValue(value: any): void {
    if (value) {
      this.selects.d.value = value.getDate();
      this.selects.m.value = value.getMonth();
      this.selects.y.value = value.getFullYear();

      this.form.setValue({
        d: this.selects.d.value,
        m: this.selects.m.value,
        y: this.selects.y.value
      });
    } else {
      if (typeof this.visible === 'boolean') {
        this.selects.d.value = this.visible ? null : 1;
        this.selects.m.value = this.visible ? null : 0;
        this.selects.y.value = this.visible ? null : new Date().getFullYear();
      } else if (this.visible instanceof Array && this.visible.length === 3) {
        this.selects.d.value = this.visible[0] ? null : 1;
        this.selects.m.value = this.visible[1] ? null : 0;
        this.selects.y.value = this.visible[2] ? null : new Date().getFullYear();
      }
    }
    this.innerValue = value;

    this.updateMonthList(this.selects.y.value);
    this.updateYearList(this.selects.m.value);
    this.updateDateList(this.selects.m.value, this.selects.y.value);
    this.cdr.markForCheck();
  }

  validate(c: AbstractControl): ValidationErrors | any {
    return this.selects.d.value !== null && this.selects.m.value !== null && this.selects.y.value !== null
      ? null
      : { required: true };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['placeholder'] && !changes['placeholder'].isFirstChange()) {
      this.updatePlaceholders();

      this.updateMonthList(this.selects.y.value);
      this.updateYearList(this.selects.m.value);
      this.updateDateList(this.selects.m.value, this.selects.y.value);
    }

    setTimeout(() => {
      if (
        this.elRef?.nativeElement?.classList.contains('ng-touched') &&
        !isOwnElement(document.activeElement, this.elRef.nativeElement)
      )
        this.form.markAllAsTouched();
    }, 10);
  }

  updateMonthList(yearParam: string) {
    const year = parseIntStrict(yearParam);

    const start =
      year !== null && this._minDate !== null && year === this._minDate.getFullYear() ? this._minDate.getMonth() : 0;
    const end =
      year !== null && this._maxDate !== null && year === this._maxDate.getFullYear() ? this._maxDate.getMonth() : 11;

    this.selects.m.options = [];
    for (let i = start; i <= end; i++) {
      this.selects.m.options.push({ value: i, name: this.monthNames[i] });
    }
  }

  updateYearList(monthParam?: string | null) {
    let month = parseIntStrict(monthParam);

    this.selects.y.options = [];
    const isReverse = typeof this.yearOrder === 'string' && this.yearOrder.indexOf('des') === 0;
    if (this._minDate && this._maxDate) {
      for (let i = this._minDate.getFullYear(); i <= this._maxDate.getFullYear(); i++) {
        const now = new Date();
        if (
          (month || 0) + 1 < this._minDate.getMonth() &&
          now.getFullYear() === i &&
          month !== null &&
          ((isReverse && (month || 0) > now.getMonth()) || (!isReverse && (month || 0) < now.getMonth()))
        ) {
          continue;
        }

        this.selects.y.options.push({ value: i, name: i });
      }
    }

    if (isReverse) {
      this.selects.y.options.reverse();
    }
  }

  updateDateList(monthParam: string, yearParam: string) {
    const month = parseIntStrict(monthParam);
    const year = parseIntStrict(yearParam);
    let start = 1;
    if (
      month !== null &&
      this._minDate !== null &&
      month === this._minDate.getMonth() &&
      year !== null &&
      year === this._minDate.getFullYear()
    ) {
      start = this._minDate.getDate();
    }

    let end = getMaxDate(month !== null ? month + 1 : null, year);
    if (
      month !== null &&
      this._maxDate !== null &&
      month === this._maxDate.getMonth() &&
      year !== null &&
      year === this._maxDate.getFullYear()
    ) {
      end = this._maxDate.getDate();
    }

    this.selects.d.options = [];
    for (let i = start; i <= end; i++) {
      this.selects.d.options.push({
        value: i,
        name: i
      });
    }
  }

  updatePlaceholders() {
    this.placeHolders = [];
    if (
      this.placeholder !== undefined &&
      this.placeholder !== null &&
      (typeof this.placeholder === 'string' || Array.isArray(this.placeholder))
    ) {
      const holders = typeof this.placeholder === 'string' ? this.placeholder.split(',') : this.placeholder;
      if (holders.length === 3) {
        for (const h of holders) {
          this.placeHolders.push(h);
        }
      }
    }
  }

  onBlur(e: Event) {
    this.blur.emit(e);
    // this.onTouchedCallback();
  }
}
