import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { AbstractValueAccessor, MakeValueProvider } from '@app/shared/utils';

@Component({
  selector: 'ln-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MakeValueProvider(CheckboxComponent)],
  standalone: false
})
export class CheckboxComponent extends AbstractValueAccessor implements OnInit {
  @Input() label!: string;
  @Input() disabled!: boolean;
  @Input() error?: string;
  @Input() name?: string;
  @Input() formControlName?: string | number;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter();

  @ViewChild('inputRef') inputElement!: ElementRef;

  String = String;

  constructor(
    protected elRef: ElementRef,
    protected renderer2: Renderer2
  ) {
    super();
  }

  ngOnInit() {
    if (this.eRef) {
      this.renderer.removeAttribute(this.eRef.nativeElement, 'id');
      this.renderer.removeAttribute(this.eRef.nativeElement, 'autocomplete');
    }
  }

  onFocus(e: Event, value: boolean) {
    (value ? this.focus : this.blur).emit(this.value);
    if (!value) this.onTouchedCallback();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (!this.inputElement) return;
    this.renderer.setProperty(this.inputElement.nativeElement, 'disabled', isDisabled);
    this.cdr.markForCheck();
  }
}
