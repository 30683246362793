import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { AbstractValueAccessor, MakeValueProvider } from '@app/shared/utils';
import { Subscription, debounceTime, filter, fromEvent, merge } from 'rxjs';

@Component({
  selector: 'ln-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [MakeValueProvider(RatingComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RatingComponent extends AbstractValueAccessor implements OnInit, OnDestroy {
  @Input() total = 5;
  @Input() showTotal = true;
  @HostBinding('class.read-only') @Input() readOnly = false;

  subMouseOut: Subscription | null = null;
  focusIdx = 0;

  constructor(
    protected elRef: ElementRef,
    protected renderer2: Renderer2
  ) {
    super();
  }

  ngOnInit() {
    const overs = fromEvent<MouseEvent>(this.elRef.nativeElement, 'mouseover');
    const outs = fromEvent<MouseEvent>(this.elRef.nativeElement, 'mouseout');

    const result = merge(overs, outs).pipe(
      debounceTime(100),
      filter((e) => e.type === 'mouseout')
    );
    if (!this.readOnly)
      this.subMouseOut = result.subscribe(() => {
        this.focusIdx = 0;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.subMouseOut?.unsubscribe();
  }

  onOver(index: number) {
    if (this.readOnly) return;
    this.focusIdx = index + 1;
  }

  onClick(index: number) {
    if (this.readOnly) return;
    this.value = index + 1;
  }
}
