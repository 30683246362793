import { NgModule } from '@angular/core';
import { TagGroupComponent } from './tag-group.component';
import { TagModule } from '../tag/tag.module';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [TagModule, SharedModule],
  declarations: [TagGroupComponent],
  exports: [TagGroupComponent]
})
export class TagGroupModule {}
