import { NgModule } from '@angular/core';
import { CheckboxComponent } from './checkbox.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [FormsModule, SharedModule],
  declarations: [CheckboxComponent],
  exports: [CheckboxComponent]
})
export class CheckboxModule {}
