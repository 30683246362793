import { NgModule } from '@angular/core';
import { FileUploadComponent } from './file-upload.component';
import { NgxUploaderModule } from '@angular-ex/uploader';
import { SharedModule } from '@app/shared/shared.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { TooltipModule } from '@comp/tooltip/tooltip.module';
import { StatusComponent } from '@comp/status/status.component';

@NgModule({
  imports: [SharedModule, NgxUploaderModule, CheckboxModule, StatusComponent, TooltipModule],
  declarations: [FileUploadComponent],
  exports: [FileUploadComponent]
})
export class FileUploadModule {}
