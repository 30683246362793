import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
@Component({
  selector: '[ln-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  constructor() {}

  @HostBinding('disabled') @Input() disabled: boolean = false;
  @HostBinding('class.small') @Input() small: boolean = false;
}
