<div class="ln-file-upload" [class]="{ disabled }" tabindex="0" (blur)="onBlur($event)">
  <div class="ln-file-upload__file-list">
    <div class="ln-file-upload__file-list-item" *ngFor="let file of files">
      <div class="ln-file-upload__file-list-item-info">
        <ln-icon [name]="getFileIconByMimeType(file.type)" class="ln-file-upload__file-list-item-icon"></ln-icon>
        <div>
          <div class="ln-file-upload__file-list-item-name">
            {{ file.name }}
          </div>
          <div *ngIf="!file.date || !canDownload">{{ humanizeBytes(file.size) }}</div>
          <div *ngIf="file.date && canDownload">{{ file.date | localizedDate }}</div>
        </div>
      </div>
      <div>
        <ln-checkbox
          *ngIf="!file.hideIdentity && !hideIdentity && !readOnly"
          [disabled]="disabled"
          [(value)]="file.identity"
          (valueChange)="onChangeIdentity(file)"
          [lnTooltip]="'components.fileUpload.tooltipId' | translate"
          >{{ 'components.fileUpload.isId' | translate }}</ln-checkbox
        >
        <div
          *ngIf="!file.hideIdentity && !hideIdentity && readOnly && file.identity"
          [lnTooltip]="'components.fileUpload.tooltipReadOnlyId' | translate"
        >
          <ln-status>{{ 'components.fileUpload.isId' | translate }}</ln-status>
        </div>
      </div>
      <ln-icon
        *ngIf="!file.denyRemove && !disabled && !readOnly"
        name="cross"
        width="16px"
        height="16px"
        class="ln-file-upload__file-list-item-remove"
        (click)="removeFile(file)"
      ></ln-icon>
      <a *ngIf="canDownload" [href]="file?.url" target="_blank" class="ln-file-upload__file-list-item-download"
        ><ln-icon name="download" width="20px" height="20px"></ln-icon>
        <div>{{ humanizeBytes(file.size) }}</div>
      </a>
    </div>
  </div>
  <div
    *ngIf="!readOnly"
    class="ln-file-upload__content"
    [class]="{ dragover: dragOver }"
    ngFileDrop
    [options]="optionsInner"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
  >
    <ln-icon name="plus" width="100px" height="100px"></ln-icon>
    <input
      [disabled]="disabled"
      #inputRef
      class="ln-file-upload__file"
      type="file"
      ngFileSelect
      [options]="optionsInner"
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput"
      multiple
      (click)="isFocused = true"
    />
    {{ 'components.fileUpload.description' | translate }}
    <div>
      {{ limitsText | translate: { files: options?.maxUploads, size: humanizeBytes(options?.maxAllSize) } }}
    </div>
  </div>
  <div class="ln-file-upload__subtext validate-error-text" *ngIf="subText || error">
    {{ subText || error }}
  </div>
</div>
