import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
  afterNextRender,
  inject
} from '@angular/core';
import { AbstractValueAccessor, MakeValueProvider } from '@app/shared/utils';
import { CompareWithFn, NgSelectComponent } from '@ng-select/ng-select/lib/ng-select.component';
import { Dictionary, Value } from './select.types';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { nanoid } from 'nanoid';

@Component({
  selector: 'ln-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MakeValueProvider(SelectComponent)],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SelectComponent extends AbstractValueAccessor implements AfterViewInit, OnDestroy {
  observer?: ResizeObserver;

  @Input() label!: string;
  @Input() ariaLabel!: string;
  @Input() id: string = nanoid(6);
  @Input() keyLabel: string = 'name';
  @Input() keyValue: string = '';
  @Input() disabled!: boolean;
  @Input() required?: boolean;
  @Input() selectOnTab!: boolean;
  @Input() searchable: boolean = true;
  @Input() placeholder!: string;
  @Input() subText?: string;
  @Input() items: Value[] | null = [];
  @Input() groupBy!: string | ((value: any) => any);
  @Input() loading = false;
  @Input() appendTo = 'body';
  @Input() compareWith!: CompareWithFn;
  @Input() clearable = true;
  @Input() multiple = false;
  @Input() maxSelectedItems!: number;
  @Input() closeOnSelect = true;
  @Input() groupTranslateKey?: string;
  @Input() autocomplete = 'off';
  @Input() name?: string;
  @Input() formControlName?: string;
  @HostBinding('dir') @Input() dir = 'auto';
  @HostBinding('class.auto-grow') @Input() autoGrow = true;
  @HostBinding('class.light') @Input() light = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter();

  @ViewChild('prefixRef') prefixRef?: ElementRef;
  @ViewChild('selectRef') selectRef?: NgSelectComponent;
  protected widthPrefix = 0;
  protected showMaxSelectedMessage = false;
  private isBrowserSub?: Subscription;
  private translate = inject(TranslateService);

  constructor() {
    super();

    afterNextRender(() => {
      if (this.prefixRef) {
        this.observer = new ResizeObserver((entries) => {
          this.widthPrefix = this.prefixRef?.nativeElement.getBoundingClientRect().width;
        });
        this.observer.observe(this.prefixRef.nativeElement);
      }
    });
  }
  onFocus(e: Event) {
    this.focus.emit(e);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    if (this.eRef) {
      this.renderer.removeAttribute(this.eRef.nativeElement, 'id');
      this.renderer.removeAttribute(this.eRef.nativeElement, 'autocomplete');
    }
  }

  ngOnDestroy() {
    if (this.observer && this.prefixRef) this.observer?.unobserve(this.prefixRef.nativeElement);
    this.isBrowserSub?.unsubscribe();
  }

  onOpen() {
    setTimeout(() => {
      const dropdown = document.querySelector('.ng-dropdown-panel:last-of-type');
      if (dropdown && dropdown instanceof HTMLElement) {
        const isRtl =
          (getComputedStyle(this.eRef.nativeElement).direction || this.translate.instant('global.direction')) === 'rtl';
        dropdown.style.setProperty('--start-position', dropdown.style.left);
        if (isRtl) dropdown.style.setProperty('direction', 'rtl');

        this.renderer.addClass(dropdown, 'visible-dropdown');
        setTimeout(() => {
          const widthW = document.documentElement.clientWidth;
          const ddRect = dropdown.getBoundingClientRect();
          if ((!isRtl && ddRect.right > widthW) || (isRtl && ddRect.left < 0)) {
            this.renderer.addClass(dropdown, 'invert-h-align');
          }
        });
      }
    });
  }

  getGroupName(item: Dictionary) {
    if (!item || typeof this.groupBy !== 'string' || item[this.groupBy] === undefined) return '';
    return this.groupTranslateKey
      ? this.translate.instant(`${this.groupTranslateKey}.${item[this.groupBy]}`)
      : item[this.groupBy];
  }

  onAdd(value: any) {
    this.showMaxSelectedMessage =
      this.multiple &&
      this.maxSelectedItems &&
      this.value?.length &&
      this.value.length === this.maxSelectedItems &&
      !this.value.includes(value);
  }

  onChange() {
    setTimeout(() => {
      this.cdr.detectChanges();
    });
  }

  onBlur(e: Event) {
    this.blur.emit(e);
    this.onTouchedCallback();
  }
}
