<div class="ln-input__prefix">
  <ng-content select="[prefix]"></ng-content>
</div>
<input
  *ngIf="!isTextArea"
  #inputRef
  [disabled]="disabled"
  [(ngModel)]="value"
  [placeholder]="placeholder"
  [attr.maxlength]="maxLength"
  (focus)="onFocus($event, true)"
  (blur)="onFocus($event, false)"
  [attr.type]="type"
  [attr.id]="id || formControlName || ''"
  [attr.name]="name"
  [attr.aria-label]="placeholder"
  [attr.autocomplete]="autocomplete || 'off'"
  [ngModelOptions]="ngModelOptions"
/>
<textarea
  *ngIf="isTextArea"
  #inputRef
  [attr.rows]="getHeight(value) || rows"
  [disabled]="disabled"
  [(ngModel)]="value"
  [ngModelOptions]="ngModelOptions"
  [placeholder]="placeholder"
  [attr.type]="type"
  [attr.id]="id || formControlName || ''"
  [attr.name]="name"
  [attr.maxlength]="maxLength"
  [attr.aria-label]="placeholder"
  [attr.autocomplete]="autocomplete"
  (focus)="onFocus($event, true)"
  (blur)="onFocus($event, false)"
></textarea>
<div *ngIf="innerValue && !disabled" class="ln-input__cross" (click)="onClear($event)"></div>
<div class="ln-input__postfix">
  <ng-content select="[postfix]"></ng-content>
</div>
<div class="ln-input__subtext" [style]="{ right: subTextRightAlign ? '0' : 'unset' }">
  <span
    *ngIf="subText"
    [class]="{ 'validate-error-text': !subTextColor }"
    [style]="{ color: subTextColor || 'var(--ln-color-error)' }"
    >{{ subText }}</span
  >
  <span *ngIf="showCount" class="ln-input__subtext-count">{{ (value || '').length }} / {{ maxLength }}</span>
</div>
