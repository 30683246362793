import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ArrowDirection } from './button.types';
import { ButtonComponent } from './button.component';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: '[ln-button-arrow]',
  templateUrl: './button-arrow.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule]
})
export class ButtonArrowComponent extends ButtonComponent {
  @Input() direction: ArrowDirection = ArrowDirection.Right;
  public ArrowDirection = ArrowDirection;
  @HostBinding('class.arrow') arrow = true;
  @HostBinding('class.big') @Input() big = false;
}
