import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Item } from '@app/shared/types';
import { AbstractValueAccessor, MakeValueProvider } from '@app/shared/utils';

@Component({
  selector: 'ln-tag-group',
  templateUrl: './tag-group.component.html',
  styleUrls: ['./tag-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MakeValueProvider(TagGroupComponent)],
  standalone: false
})
export class TagGroupComponent extends AbstractValueAccessor {
  @Input() viewMore!: number;
  @Input() label!: string;
  @Input() onlyOne!: boolean;
  @Input()
  get items() {
    return this._items;
  }
  set items(value: Item[]) {
    this._items = value;
    this.setSelected(this.innerValue as Item | Item[]);
  }

  @Output() showedMore = new EventEmitter<boolean>();

  isShowedMore = false;
  selected: boolean[] = [];
  _items: Item[] = [];

  override writeValue(value: Item | Item[]) {
    this.innerValue = value;
    this.setSelected(value);
  }

  setSelected(value: Item | Item[]) {
    if (this.onlyOne || !value) this.selected = [];
    if (!value) return;

    const uniValue = 'length' in value ? value : [value];
    if (uniValue.length) {
      ('length' in value ? value : [value]).forEach((value) => {
        const findedIndex = this.items.findIndex((item) => item === value);
        if (findedIndex !== -1) this.selected[findedIndex] = true;
      });
    }
  }

  get lessItems() {
    return this.isShowedMore ? this.items : this.items.slice(0, this.viewMore);
  }

  onMore(event: Event) {
    event.stopPropagation();
    this.isShowedMore = !this.isShowedMore;
    this.showedMore.emit(this.isShowedMore);
  }

  onTag(index: number) {
    const prevValue = this.selected[index];
    if (this.onlyOne) this.selected = [];
    this.selected[index] = prevValue;
    this.selected[index] = !this.selected[index];
    const value = this.selected
      .map((isSelected, index) => (isSelected ? this.items[index] : null))
      .filter((item) => !!item);
    if (this.onlyOne) this.value = value.length ? value[0] : null;
    else this.value = value;
  }
}
