<label *ngIf="label" class="ln-select__label label-text" id="labelId">{{ label }}</label>
<div class="ln-select__input">
  <div class="ln-select__input-prefix" #prefixRef>
    <ng-content select="[prefix]"></ng-content>
  </div>
  <ng-select
    [style]="{ width: widthPrefix ? 'calc(100% - ' + widthPrefix + 'px)' : '100%' }"
    [items]="items"
    [bindLabel]="keyLabel"
    [bindValue]="keyValue"
    [(ngModel)]="value"
    (add)="onAdd($event)"
    [disabled]="disabled"
    [required]="!!required"
    [ariaLabel]="ariaLabel || placeholder || label || ''"
    [compareWith]="compareWith"
    [searchable]="searchable"
    [clearable]="clearable"
    [multiple]="multiple"
    [loading]="loading"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (ngModelChange)="onChange()"
    (open)="onOpen()"
    [groupBy]="groupBy"
    [appendTo]="appendTo"
    [selectOnTab]="selectOnTab"
    [closeOnSelect]="closeOnSelect"
    [maxSelectedItems]="maxSelectedItems"
    [labelForId]="id"
    [inputAttrs]="{
      autocomplete,
      name: name || formControlName || ''
    }"
    #selectRef
  >
    <ng-template ng-optgroup-tmp let-item="item">
      {{ getGroupName(item) }}
    </ng-template>

    <ng-template ng-placeholder-tmp
      ><div class="ng-placeholder">{{ placeholder }}</div></ng-template
    >
  </ng-select>
</div>
<div class="ln-select__subtext error validate-error-text" *ngIf="subText">
  {{ subText }}
</div>

<div
  class="ln-select__subtext warning"
  *ngIf="multiple && maxSelectedItems && value?.length === maxSelectedItems && showMaxSelectedMessage"
>
  {{ 'controls.select.maxItems' | translate }}
</div>
